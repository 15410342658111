.wrapper {
  margin-top: 78px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 80px 20px 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.title {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}
.subTitle {
  font-size: 18px;
  color: #666;
  margin-top: 6px;
  transition: 0.3s ease-in-out;
}

/* 버튼 */
.btn_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #dff0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5caec7;
}

/* ========= */
.titleLink {
  color: #6e8d96;
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin: 30px 0 20px;
}

.titleLink img {
  margin-top: 2px;
}

/* 보증 정보 값 */
.valBox1 {
  width: 100%;
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: space-between;
}

.valBox1 li {
  width: 100%;
  border-radius: 12px;
  background-color: #fafafa;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.valBox1 li p {
  color: #797979;
}

.valBox1 li h1 {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 500;
}

.valBox1 li h1 span {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

.valBtn {
  width: 100%;
  font-size: 16px;
  margin-top: 20px;
  background-color: #6cbed7;
  height: 44px;
  color: #fff;
  transition: 0.3s ease-in-out;
}

.valBtn:hover {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
}

/* 콜센터 안내 */
.call_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0 20px;
}

.call_title p {
  color: #666;
}

.call_title p img {
  margin-right: 6px;
}

.call_title h1 {
  font-size: 20px;
}

.call_wrap a {
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
  background-color: #eaf8fc;
  color: #6cbed7;
  transition: 0.3s ease-in-out;
}

.call_wrap a:hover {
  background-color: #dff0f5;
}

/* 내 정보 데이터 */
.valBox2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.valBox2 li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  background-color: #fafafa;
  border-radius: 12px;
}

.valBox2 li p {
  font-size: 14px;
  color: #797979;
}

.valBox2 li h1 {
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 0;
  }

  .title {
    font-size: 24px;
  }

  .subTitle {
    font-size: 16px;
    max-width: 220px;
  }

  /* 보증 정보 값 */
  .valBox1 {
    flex-direction: column;
    transition: 0.3s ease-in-out;
  }

  .valBox1 li {
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
  }

  .valBox1 li h1 {
    margin-top: 0;
    font-size: 16px;
    transition: 0.3s ease-in-out;
  }
}
