.h_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  border-bottom: 2px solid #eee;
  z-index: 999;
}

.h_wrapper_home {
  background-color: transparent;
  border: none;
  z-index: 999;
}

.h_wrapper_home_active {
  background-color: #fff;
  border-bottom: 2px solid #eee;
  z-index: 999;
}

.h_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  padding: 16px 40px;
  transition: all 0.3s ease-in-out;
}

.h_container_kbc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  padding: 16px 40px;
  transition: all 0.3s ease-in-out;
}

.h_container_kbc_collabo {
  padding: 0 20px;
  color: #999;
  font-size: 16px;
  font-weight: 1000;
}

.signUp {
  padding: 12px 16px;
  color: #6cbed7;
  background-color: #eaf8fc;
  width: 100%;
  max-width: 90px;
  min-width: 90px;
  margin-right: 10px;
  transition: all 0.3s ease-in-out;
}

.signUp:hover {
  color: #6cbed7;
  background-color: #e1eef3;
}

.login {
  padding: 12px 23px;
  background-color: #6cbed7;
  color: #fff;
  border-radius: 0;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
}

.login:hover {
  background-color: #5caec7;
  color: #fff;
}

/* 로그인시 유저 */
.user_box {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  position: relative;
}

.user_box {
  font-size: 18px;
  font-weight: 500;
  color: #666;
}

.userMenu_wrap {
  position: absolute;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
}

.userMenu_wrap a {
  min-width: 100px;
  padding: 0 14px;
  height: 50px;
  background-color: #fff;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  font-size: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.user_box img {
  margin-top: 2px;
}

/* 푸터 */
.f_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #393c43;
  margin-top: 120px;
}

.f_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  width: 100%;
  max-width: 400px;
}

.f_side_menu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0 40px;
  border-bottom: 1px solid #53555a;
  margin-bottom: 40px;
}

.f_side_menu ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120px;
  width: 100%;
}

.f_side_menu ul li {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.f_side_menu ul a {
  font-size: 14px;
  color: #fff;
  padding: 10px;
  color: #e2e2e2;
  margin-top: 6px;
}

.f_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.f_info p {
  text-align: center;
  color: #fff;
  font-size: 13px;
  margin: 10px 0 20px;
}

.info_navBox {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 30px;
}

.info_navBox a {
  color: #ccc;
  font-size: 13px;
  padding: 10px;
}

.socialBox a img {
  width: 30px;
}

.socialBox {
  display: flex;
  justify-content: center;
  column-gap: 14px;
  margin: 20px 0 20px;
}

.f_info span {
  font-size: 12px;
  color: #aaa;
}

@media screen and (max-width: 900px) {
  .h_container {
    padding: 16px;
  }

  .h_container a img {
    width: 130px;
  }

  .signUp {
    font-size: 14px;
    padding: 10px;
  }
  .login {
    font-size: 14px;
    padding: 10px;
  }

  /* 로그인시 유저 */

  .user_box {
    font-size: 16px;
  }

  .f_wrapper {
    margin-top: 80px;
  }
}
