.wrapper {
  margin-top: 78px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 80px 20px 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.title {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}
.subTitle {
  font-size: 18px;
  color: #666;
  margin-top: 6px;
  transition: 0.3s ease-in-out;
  margin-bottom: 40px;
}

/* 버튼 */
.btn_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #dff0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5caec7;
}

/* ========= */
.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formBox label {
  font-size: 14px;
  margin-bottom: 6px;
  color: #666;
}

.label {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.formBox label span {
  color: #f13838;
  font-size: 12px;
}

.errorMsg {
  color: #f73f51;
  font-size: 11px;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* 성별선택 */
.genderBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.genderBox label {
  width: 100%;
}

.genderBox input[type="radio"] {
  display: none;
}

.genderBox input[type="radio"] + span {
  display: flex;
  justify-content: center;
  background: none;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 14px 12px;
  font-size: 15px;
  color: #ccc;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.genderBox input[type="radio"] + span:hover {
  background-color: #eaf8fc;
}

.genderBox input[type="radio"]:checked + span {
  border: none;
  background-color: #eaf8fc;
  color: #60b3cc;
  font-weight: 500;
}

/* 투필드 - 인풋옆 버튼있을 때 */
.two_field {
  width: 100%;
  display: flex;
  column-gap: 6px;
}

.input_field2 {
  width: 100%;
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* 파일선택 */
.two_field input[type="file"] {
  display: none;
}

.two_field button {
  min-width: 76px;
  height: 100%;
  background-color: #60b3cc;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.two_field button:hover {
  background-color: #5aaec7;
}

/* 거주지 > 셀렉트 박스 */
.selet_fild {
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.selet_fild select {
  padding: 14px 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 15px;
}

.checkBox {
  display: none;
}

.checkBox + label {
  padding-left: 26px;
  padding-bottom: 1px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../../../../@assets/signup/check-none.svg");
  cursor: pointer;
}

.checkBox:checked + label {
  background-image: url("../../../../@assets/signup/check-active.svg");
}
.check_box {
  padding: 16px 14px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.check_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 0;
  }

  .title {
    font-size: 24px;
  }

  .subTitle {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
