.wrapper {
  margin-top: 78px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 80px 20px 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.title {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}
.subTitle {
  font-size: 18px;
  color: #666;
  margin-top: 6px;
  transition: 0.3s ease-in-out;
}

/* 버튼 */
.btn_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #dff0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5caec7;
}

/* ========= */
.titleLink {
  color: #6e8d96;
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin: 30px 0 20px;
}

.titleLink img {
  margin-top: 2px;
}

/* 보증 정보 값 */
.valBox1 {
  width: 100%;
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  justify-content: space-between;
}

.valBox1 li {
  width: 100%;
  border-radius: 12px;
  background-color: #fafafa;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.valBox1 li p {
  color: #797979;
}

.valBox1 li h1 {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 500;
}

.valBox1 li h1 span {
  font-size: 14px;
  font-weight: 400;
  color: #999;
}

/* 보증 서비스 절차 */
.sec_title {
  font-weight: 500;
  font-size: 22px;
  line-height: 1.3;
  margin-top: 80px;
}

.sec2_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  margin-top: 20px;
}

.sec2_wrap li {
  display: flex;
  align-items: center;

  padding: 14px;
  border-radius: 12px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.06);
}

.sec2_wrap li p {
  margin-left: 10px;
  color: #666;
}

.sec2_wrap li p span {
  font-weight: 500;
}

/* 보증 서비스 부품 */
.sec3_subTitle {
  font-size: 14px;
  color: #666;
}

.check_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 20px;
}

.check_wrap li {
  padding: 12px 16px;
  background-color: #fafafa;
  border-radius: 12px;
}

/* 체크박스 */
.checkBox {
  display: none;
}
.checkBox + label {
  padding-left: 26px;
  padding-bottom: 1px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../../../../@assets/signup/check-none.svg");
  cursor: pointer;
}

.checkBox:checked + label {
  background-image: url("../../../../@assets/signup/check-active.svg");
}

/* 고장증상 입력 */
.sec4_subTitle {
  font-size: 14px;
  margin: 20px 0;
  color: #6e8d96;
}

.input_field {
  padding: 14px;
  border-radius: 12px;
  border-color: #ccc;
  font-size: 16px;
}

.input_field::placeholder {
  color: #ccc;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 0;
  }

  .title {
    font-size: 24px;
  }

  .subTitle {
    font-size: 16px;
  }

  /* 보증 정보 값 */
  .valBox1 {
    flex-direction: column;
    transition: 0.3s ease-in-out;
  }

  .valBox1 li {
    width: auto;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.3s ease-in-out;
  }

  .valBox1 li h1 {
    margin-top: 0;
    font-size: 16px;
    transition: 0.3s ease-in-out;
  }

  /* 보증 서비스 절차 */
  .sec_title {
    font-size: 20px;
  }
}
