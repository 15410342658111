.wrapper {
  margin-top: 78px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 80px 20px 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.title {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}
.subTitle {
  font-size: 18px;
  color: #666;
  margin-top: 6px;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}

.label {
  margin-bottom: 6px;
  font-size: 14px;
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #666;
}

.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.errorMsg {
  color: #f73f51;
  font-size: 11px;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.btn_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #dff0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5caec7;
}

.errorBox {
  width: 100%;
  background-color: #fff4f4;
  border-radius: 8px;
  padding: 4px 0;
  margin-top: 16px;
}

.errorBox p {
  color: #f73f51;
  font-size: 13px;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 0;
  }

  .title {
    font-size: 24px;
  }

  .subTitle {
    font-size: 16px;
    max-width: 220px;
    margin-bottom: 10px;
  }
}
