.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

/* 첫번째 섹션 */
.sec1_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 800px;
  background-image: linear-gradient(136deg, #f3f7fc 16%, #e9f1fa 73%);
}

.sec1_container {
  width: 100%;
  max-width: 1200px;
  padding: 78px 0 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

/* 박스 */
.box_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
  position: absolute;
  bottom: -220px;
  transition: 0.2s ease-in-out;
}

.box_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  align-items: center;
  transition: 0.2s ease-in-out;
}

.box_wrapper h1 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  transition: 0.2s ease-in-out;
}

.box_wrapper p {
  font-size: 16px;
  text-align: center;
  color: #555;
  margin-top: 10px;
  transition: 0.2s ease-in-out;
}

.content_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.2s ease-in-out;
}

.content_wrap li {
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  transition: 0.2s ease-in-out;
}

.content_wrap li h1 {
  font-size: 18px;
  font-weight: 600;
  margin-top: 12px;
  transition: 0.2s ease-in-out;
}

.content_wrap li p {
  white-space: pre-line;
  font-size: 14px;
  margin-top: 6px;
}

/* 두번째 섹션 */
.sec2_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec2_container {
  max-width: 1200px;
  width: 100%;
  padding: 400px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec2_container h1 {
  text-align: center;
  font-size: 34px;
}

.sec2_container p {
  font-weight: 500;
  font-size: 24px;
  margin: 6px 0 20px;
  color: #555;
}

.content2_wrap {
  width: 100%;
  display: flex;
  column-gap: 20px;
  margin-top: 60px;
}

.content2_wrap li {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 285px;
  padding: 20px;
  background-color: #f8fbfd;
  border-radius: 12px;
}

.content2_wrap li h1 {
  font-size: 18px;
  margin: 12px 0 8px;
}

.content2_wrap li p {
  display: flex;

  font-size: 14px;
  color: #666;
  font-weight: 400;
  margin: 4px 0 0;
  max-width: 210px;
}

.content2_wrap li p span {
  min-width: 6px;
  height: 6px;
  background-color: #aaa;
  margin-right: 8px;
  margin-top: 7px;
  border-radius: 2px;
}

/* 세번째 섹션 */
.sec3_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  margin-top: 140px;
}

.sec3_container {
  width: 100%;
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
}

.sec3_container h1 {
  font-size: 34px;
  text-align: center;
  line-height: 1.35;
  transition: 0.2s ease-in-out;
}

.sec3_container p {
  color: #555;
  margin: 10px 0 20px;
  font-size: 20px;
  transition: 0.2s ease-in-out;
}

.sec3_numb_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec3_numb_wrap li {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec3_numb_wrap li a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 14px;
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  transition: 0.2s ease-in-out;
}

.sec3_numb_wrap li a p {
  font-size: 18px;
  margin: 10px 0 0;
  text-align: center;
  transition: 0.2s ease-in-out;
}

.sec3_btnBox {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 60px;
}

.sec3_btn1 {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaf8fc;
  border: 1px solid #cee2e8;
  border-radius: 12px;
  color: #6cbed7;
  font-size: 18px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

.sec3_btn1:hover {
  color: #6cbed7;
  background-color: #e9f5fa;
}

.sec3_btn2 {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6cbed7;
  border-radius: 12px;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  transition: 0.2s ease-in-out;
}

.sec3_btn2:hover {
  background-color: #5caec7;
  color: #fff;
}

@media screen and (max-width: 1200px) {
  .sec1_wrapper {
    height: auto;
  }

  /* 박스 */
  .box_wrapper {
    position: relative;
    bottom: auto;
    box-shadow: none;
    margin-top: 60px;
    border-radius: 0;
    background-color: #e3edf8;
  }

  /* 두번쨰섹션 */
  .sec2_container {
    max-width: 900px;
    padding: 120px 20px 0;
  }

  .content2_wrap {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }
}

@media screen and (max-width: 580px) {
  .box_wrapper {
    background-color: #fff;
  }

  .box_container {
    padding: 60px 20px;
  }

  .box_wrapper p {
    max-width: 350px;
  }

  .content_wrap {
    flex-direction: column;
    align-items: center;
  }

  /* 두번째 섹션 */

  .sec2_container {
    border-top: 10px solid #fafafa;
    padding: 80px 20px 0;
  }

  .sec2_container h1 {
    font-size: 24px;
    max-width: 240px;
  }

  .sec2_container p {
    font-size: 18px;
    margin: 6px 0 16px;
  }

  .content2_wrap {
    margin-top: 40px;
  }

  /* 세번째 섹션 */
  .sec3_container h1 {
    font-size: 24px;
  }

  .sec3_container p {
    font-size: 18px;
  }

  .sec3_numb_wrap li a p {
    font-size: 16px;
  }

  .sec3_btnBox {
    flex-direction: column;
    row-gap: 10px;
    margin-top: 40px;
  }

  .sec3_btn1,
  .sec3_btn2 {
    font-size: 16px;
    height: 54px;
  }
}
