.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 78px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 20px 0;
  max-width: 900px;
  transition: 0.3s ease-in-out;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
  transition: 0.3s ease-in-out;
}

.nav_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  overflow-x: auto;
  transition: 0.3s ease-in-out;
}

.nav_wrap a {
  width: 100px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  transition: 0.3s ease-in-out;
}

.q_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-top: 30px;
}

.q_box_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.q_box_wrapper_active {
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.q_box_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  transition: 0.3s ease-in-out;
}

.titleBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBox h1 {
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s ease-in-out;
}

.titleBox img {
  margin-bottom: 4px;
}

.q_box_container p {
  font-size: 14px;
  margin-top: 10px;
  color: #666;
  font-weight: 300;
}

@media screen and (max-width: 900px) {
  .container {
    padding: 30px 20px 0;
  }
  .title {
    font-size: 24px;
  }

  .nav_wrap {
    column-gap: 8px;
    row-gap: 8px;
  }

  .nav_wrap a {
    width: 90px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .q_wrapper {
    margin-top: 20px;
  }
}
