.layer {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  transition-duration: 0.4s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.wrapper {
  position: fixed;
  top: -100%;
  left: 0;
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  width: 100%;
  flex: 1;
  display: flex;
  z-index: 9998;
  justify-content: center;
}

.wrapper_active {
  top: 50%;
  transform: translateY(-50%);
}

.container {
  width: 100%;
  max-width: 400px;
  padding: 50px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
}

.xBtn {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

.container h1 {
  font-size: 28px;
  text-align: center;
  line-height: 1.35;
  margin-bottom: 24px;
  transition: 0.3s ease-in-out;
}

/* 인풋필드 */
.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 14px;
  color: #666;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.formBox button {
  width: 100%;
  height: 54px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.formBox button:hover {
  background-color: #5caec7;
}

/* 에러메시지 */
.errorBox {
  width: 100%;
  background-color: #fff4f4;
  border-radius: 8px;
  padding: 4px 0;
  margin-top: 16px;
}

.errorBox p {
  color: #f73f51;
  font-size: 13px;
  text-align: center;
}

/* 아이디 저장 */
.checkBox {
  display: none;
}
.checkBox + label {
  font-size: 13px;
  font-weight: 400;
  color: #797979;
  margin-top: 10px;
  padding-left: 22px;
  padding-bottom: 1px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url('../../../@assets/loginCheckIcon_n.svg');
  cursor: pointer;
}

.checkBox:checked + label {
  background-image: url('../../../@assets/loginCheckIcon_a.svg');
}

.forgetBox {
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding: 20px 0 30px;
  border-bottom: 6px solid #fafafa;
  margin-bottom: 20px;
}

.forgetBox a {
  font-size: 14px;
  color: #797979;
}

.forgetBox span {
  width: 1px;
  height: 100%;
  background-color: #e2e2e2;
}

/* 추가 버튼박스 */
.signUp_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signUp_box h2 {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #6cbed7;
  margin-bottom: 12px;
}

.sighUp_btn {
  background-color: #eaf8fc;
  width: 180px;
  height: 46px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5caec7;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}

.sighUp_btn:hover {
  background-color: #ddf1f7;
  color: #5caec7;
}

@media screen and (max-width: 700px) {
  .wrapper {
    top: 1000%;
  }

  .wrapper_active {
    top: 30px;
    transform: translateY(0);
  }

  .container {
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: 20px 20px 0 0;
    height: 100vh;
  }
  .container h1 {
    font-size: 24px;
    text-align: start;
    margin-bottom: 14px;
  }
}
