.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 78px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px 0;
  max-width: 500px;
  transition: 0.3s ease-in-out;
}

.container h1 {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}

.container p {
  color: #666;
  margin: 6px 0 30px;
  transition: 0.3s ease-in-out;
}

.formBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 인풋필드 */
.label {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 14px;
  color: #666;
}

.label span {
  color: #f73f51;
  font-size: 11px;
  font-weight: 400;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.textarea_field {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.input_field_area {
  width: 100%;
  font-size: 15px;
  padding: 14px 12px;
  border: none;
  background-color: transparent;
  outline: none;
}

.input_field_area::placeholder {
  color: #ccc;
}

.btnBox {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.line {
  background-color: #f5f5f5;
  width: 100%;
  height: 10px;
  margin: 30px 0 20px;
}

.btn1 {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaf8fc;
  color: #6cbed7;
  border-radius: 12px;
  font-size: 16px;
  transition: 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #def0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6cbed7;
  color: #fff;
  border-radius: 12px;
  font-size: 16px;
  transition: 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5eb0c9;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 30px 20px 0;
    align-items: flex-start;
  }

  .container h1 {
    font-size: 24px;
  }

  .container p {
    color: #666;
    margin: 6px 0 20px;
  }

  .line {
    margin: 20px 0 20px;
  }
}
