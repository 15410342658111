.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 78px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  max-width: 900px;
}

.container h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.container p {
  color: #666;
  font-size: 15px;
}

@media screen and (max-width: 700px) {
  .container {
    padding: 30px 20px;
  }

  .container h1 {
    font-size: 24px;
  }
}
