.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px 30px;
  max-width: 500px;
}

/* PathBar 스타일 */
.path_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
}

.path_header h5 {
  font-size: 32px;
  margin: 0 0 40px 0;
}

.pathBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.pathBar li {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pathBar li span {
  width: 100%;
  max-width: 40px;
  height: 40px;
  background-color: #e2e2e2;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.pathBar li p {
  font-size: 14px;
  color: #ccc;
  margin-top: 4px;
}

.line {
  position: absolute;
  top: 20px;
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
}

/* sign-up1 가입안내 */
.sign1_title h1 {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

.sign1_title p {
  color: #797979;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 40px;
}

.sign1_infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.sign1_infoBox li {
  padding: 16px;
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  color: #666;
  border-radius: 10px;
}

/* sign-up2 회원정보 */
.checkForm {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* 전체동의 */
.allCheck_box {
  padding: 16px 14px;
  background-color: #f8fbff;
  border: 1px solid #e9f1fa;
  border-radius: 10px;
  margin-bottom: 30px;
}
.checkBox {
  display: none;
}
.checkBox + label {
  padding-left: 26px;
  padding-bottom: 1px;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("../../../@assets/signup/check-none.svg");
  cursor: pointer;
}

.checkBox:checked + label {
  background-image: url("../../../@assets/signup/check-active.svg");
}

/* 일부체크박스 */
.check_box {
  padding: 16px 14px;
  background-color: #f9f9f9;
  border-radius: 10px;
}

.check_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.check_title a {
  font-size: 13px;
  color: #999999;
}

.check_title a img {
  margin-left: 4px;
}

.check_info {
  font-size: 14px;
  color: #797979;
  line-height: 1.35;
  margin-top: 6px;
  margin-left: 27px;
  max-width: 360px;
}

/* 이용약관 */
.info_desk {
  padding: 14px;
  border: 1px solid #e2e2e2;
  margin-top: 6px;
  border-radius: 6px;
  font-size: 14px;
  color: #666;
  max-height: 150px;
  overflow-y: auto;
}

.info_desk::-webkit-scrollbar {
  width: 4px;
}

/* sign-up3 인풋 폼박스 css  */
.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.formBox label {
  font-size: 14px;
  margin-bottom: 6px;
  color: #666;
}

.label {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.formBox label span {
  color: #f13838;
  font-size: 12px;
}

.errorMsg {
  color: #f73f51;
  font-size: 11px;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

/* 성별선택 */
.genderBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}

.genderBox label {
  width: 100%;
}

.genderBox input[type="radio"] {
  display: none;
}

.genderBox input[type="radio"] + span {
  display: flex;
  justify-content: center;
  background: none;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  padding: 14px 12px;
  font-size: 15px;
  color: #ccc;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.genderBox input[type="radio"] + span:hover {
  background-color: #eaf8fc;
}

.genderBox input[type="radio"]:checked + span {
  border: none;
  background-color: #eaf8fc;
  color: #60b3cc;
  font-weight: 500;
}

/* 투필드 - 인풋옆 버튼있을 때 */
.two_field {
  width: 100%;
  display: flex;
  column-gap: 6px;
}

.input_field2 {
  width: 100%;
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.two_field button {
  min-width: 76px;
  height: 100%;
  background-color: #60b3cc;
  border-radius: 10px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
}

.two_field button:hover {
  background-color: #5aaec7;
}

/* 거주지 > 셀렉트 박스 */
.selet_fild {
  width: 100%;
  display: flex;
  column-gap: 10px;
}

.selet_fild select {
  padding: 14px 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 15px;
}

/* sign-up4 인풋 폼박스 css  */
/* 파일선택 */
.two_field input[type="file"] {
  display: none;
}

/* sign-up5 css  */
.signUp5_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.signUp5_wrap h1 {
  color: #16b560;
  font-weight: 500;
  font-size: 24px;
  margin: 10px 0 4px;
}

.signUp5_wrap h2 {
  font-size: 20px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 8px;
}

.signUp5_wrap p {
  font-size: 16px;
  color: #797979;
}

.singup5_btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.singup5_btn1:hover {
  background-color: #dff0f5;
}

.singup5_btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.singup5_btn2:hover {
  background-color: #5caec7;
}

/* 버튼 */
.btn {
  width: 100%;
  height: 54px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn:hover {
  background-color: #5caec7;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px;
  }
  /* PathBar 스타일 */
  .path_header {
    margin-bottom: 30px;
  }

  .path_header h5 {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .pathBar li span {
    max-width: 30px;
    height: 30px;
  }

  .line {
    top: 15px;
  }

  /* sign-up1 가입안내 */
  .sign1_title h1 {
    font-size: 20px;
    margin-top: 10px;
  }

  .sign1_title p {
    font-size: 14px;
    max-width: 260px;
    margin-top: 6px;
    margin-bottom: 20px;
  }

  .sign1_infoBox li {
    font-size: 14px;
  }

  /* 전체동의 */
  .allCheck_box {
    margin-bottom: 20px;
  }

  /* sign-up3 인풋 폼박스 css  */
  .formBox label {
    font-size: 13px;
  }

  /* sign-up5 css  */
  .signUp5_wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .signUp5_wrap img {
    width: 40px;
  }

  .signUp5_wrap h1 {
    font-size: 20px;
  }

  .signUp5_wrap h2 {
    font-size: 18px;
  }
}

.contractImg {
  display: none;
}
