.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 78px;
}

/* 섹션 1 */
.sec1_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  transition: 0.3s ease-in-out;
}

.sec1_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  max-width: 900px;
  transition: 0.3s ease-in-out;
}

.sec1_container h1 {
  font-size: 30px;
  font-weight: 500;
  margin-top: 60px;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
}

.sec1_container h1 span {
  font-weight: 600;
}

.sec1_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}

.sec1_wrap li {
  width: calc(50% - 20px);
  background-color: #f9fafb;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  height: 200px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.sec1_content {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 26px 20px;
  transition: 0.3s ease-in-out;
}

.sec_content_btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  min-width: 84px;
  height: 34px;
  background-color: #6cbed7;
  border-radius: 0;
  color: #fff;
  white-space: nowrap;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.sec_content_btn:hover {
  background-color: #66b9d3;
}

.titleBox {
  margin-left: 20px;
}

.titleBox h2 {
  font-size: 18px;
}

.titleBox h3 {
  font-size: 14px;
  white-space: pre-line;
  line-height: 1.35;
  font-weight: 400;
  margin-top: 6px;
  transition: 0.3s ease-in-out;
}

.titleBox p {
  font-size: 14px;
  white-space: pre-line;
  margin-top: 20px;
  transition: 0.3s ease-in-out;
}

.signUp_btn {
  width: 100%;
  height: 54px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 30px;
  background-color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp_btn:hover {
  background-color: #5caec7;
  color: #fff;
}

/* 섹션2 */
.sec2_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  margin-top: 100px;
  transition: 0.3s ease-in-out;
}

.sec2_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 900px;
  padding: 60px 20px;
  transition: 0.3s ease-in-out;
}

.sec2_container h1 {
  font-size: 28px;
  margin-bottom: 30px;
  transition: 0.3s ease-in-out;
}

.sec2_wrap {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.sec2_container li {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 12px;
}

.sec2_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.sec2_content h2 {
  font-size: 16px;
  margin-bottom: 8px;
  transition: 0.3s ease-in-out;
}
.sec2_content p {
  font-size: 14px;
  color: #555;
  transition: 0.3s ease-in-out;
}

.edge_title {
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
}

.sec2_edge_wrap {
  display: flex;
  flex-direction: column;
}

.sec2_edge_wrap li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  list-style: none;
  margin-top: 12px;
  font-size: 13px;
}

.sec2_edge_wrap li span {
  font-weight: 500;
  white-space: nowrap;
  margin-right: 10px;
}

.sec2_edge_wrap li p {
  color: #666;
  font-weight: 400;
}

/* 섹션3 */
.sec3_wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.sec3_container {
  width: 100%;
  padding: 100px 20px 0;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sec3_title {
  font-size: 24px;
  margin-bottom: 60px;
}

.sec3_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 14px;
}

.sec3_wrap li {
  width: 100%;
  display: flex;
  background-color: #f9fafb;
  border-radius: 12px;
}

.sec3_content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
}

.sec3_titleBox {
  margin-left: 16px;
}

.sec3_titleBox h1 {
  font-size: 16px;
  margin-bottom: 6px;
}

.sec3_titleBox p {
  font-size: 14px;
  color: #555;
  margin-top: 4px;
}

@media screen and (max-width: 900px) {
  .sec1_container h1 {
    margin-top: 30px;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .sec1_content {
    flex-direction: column;
  }

  .sec1_wrap {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }

  .sec_content_btn {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 14px;
  }

  .sec1_wrap li {
    width: 100%;
    height: auto;
  }

  .titleBox {
    margin-left: 0;
    margin-top: 10px;
  }

  .signUp_btn {
    margin-top: 20px;
  }

  /* 섹션2 */
  .sec2_wrapper {
    margin-top: 80px;
  }
  .sec2_container {
    padding: 40px 20px;
  }
  .sec2_container h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  /* 섹션3 */
  .sec3_container {
    padding: 80px 20px 0;
  }
  .sec3_title {
    font-size: 24px;
    margin-bottom: 40px;
  }
}
