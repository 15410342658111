/* 로딩화면 */
.loading_wrap {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-image: linear-gradient(136deg, #529ab2 4%, #4b6f9f 98%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.container {
  margin-top: 78px;
  padding: 60px 20px 120px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.backBox {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  column-gap: 14px;
  cursor: pointer;
}

.logo {
  width: 120px;
}

.title {
  font-size: 30px;
  color: #fff;
  font-weight: 500;
  transition: 0.3s ease-in-out;
  line-height: 1.3;
}

/* 패스라인 */
.pathBar_wrap {
  width: 100%;
  display: flex;
  margin: 40px 0 60px;
  z-index: 100;
}

.pathBar {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.pathBar li {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pathBar li span {
  width: 100%;
  max-width: 40px;
  height: 40px;
  background-color: #e2e2e2;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.pathBar li p {
  font-size: 16px;
  color: #ccc;
  margin-top: 6px;
  transition: 0.3s ease-in-out;
}

.line {
  position: absolute;
  top: 20px;
  width: 100%;
  height: 1px;
  background-color: #7ba1b3;
}

/* step 타이틀 */
.step_wrap {
  width: 100%;
}

.step_wrap h2 {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.step_wrap h2 span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

/* step1 페이지 */
.choice_box {
  display: flex;
  column-gap: 10px;
  margin: 0 0 30px;
}

.choice_box label {
  width: 100%;
  max-width: 200px;
  height: 48px;
}

.choice_box label input[type="radio"] {
  display: none;
}

.choice_box label input[type="radio"] + span {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c7ca0;
  height: 48px;
  cursor: pointer;
  color: #adcbd9;
}

.choice_box label input[type="radio"]:checked + span {
  background-color: #4fb4d4;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.menu_box {
  width: 100%;
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
}

.menu_box label.active {
  background-color: #4fb4d4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
}

.menu_box label input[type="radio"] {
  display: none;
}

.menu_box label {
  width: 100%;
  max-width: 160px;
  height: 120px;
  background-color: #3a7394;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu_box label img {
  color: #adcbd9;
}

.menu_box label p {
  margin-top: 8px;
  color: #adcbd9;
}
.menu_box label.active p {
  color: #fff;
}

/* background-color: #4fb4d4; */

.step1_subP {
  margin-top: 60px;
  font-size: 14px;
  color: #cee1e7;
}

/* step2 페이지 */
.step2_input_wrap {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.step2_input_wrap input {
  width: 100%;
  background-color: #3e7a9c;
  outline: none;
  border: none;
  padding: 14px;
  color: #fff;
  font-size: 16px;
}

.step2_input_wrap p {
  font-size: 18px;
  color: #fff;
}

.step2_btn_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.step2_btn_wrap button {
  min-width: 200px;
  height: 50px;
  border-radius: 0;
  font-size: 16px;
  background-color: #54b9d8;
  color: #fff;
  transition: all 0.3s ease-in-out;
}

/* step3 페이지 */
.step3_type_wrap {
  width: 100%;
  display: flex;
  column-gap: 20px;
}

.step3_type_wrap a {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
}

.type_abled {
  background-color: #3c7ca0;
  transition: all 0.3s ease-in-out;
  color: #fff;
}

.type_abled:hover {
  color: #fff;
  background-color: #417ea1;
}

.type_abled span {
  font-size: 14px;
  color: #e2e2e2;
}

.type_disabled {
  background-color: #25536c;
  color: #999;
  pointer-events: none;
}

.type_disabled:hover {
  color: #999;
  pointer-events: none;
}

.type_disabled span {
  color: #999;
  font-size: 14px;
  pointer-events: none;
}

/* step4 페이지 */
.sec4_value {
  width: 100%;
  display: flex;
  align-items: flex-end;
}

.sec4_value h3 {
  font-size: 30px;
  border-bottom: 1px solid #fff;
  color: #fff;
}

.sec4_value span {
  color: #fff;
}

.sec4_btn {
  width: 100%;
  height: 54px;
  color: #fff;
  font-size: 16px;
  background-color: #4fb4d4;
  margin-top: 30px;
  border-radius: 0;
  transition: 0.3s ease-in-out;
}

.sec4_btn:hover {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
}

/* KB 차차차 외부에서 접속한 페이지 */
.outside_page_title {
  font-size: 14px;
  color: #fff;
  margin-bottom: 6px;
}

.outP_wrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.outP_wrap li {
  width: 100%;
  display: flex;
  justify-content: center;
}

.outP_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.outside_page_value {
  padding: 14px;

  background-color: #3c7ca0;
  color: #fff;
}

.outside_page_value span {
  margin-left: 10px;
  color: #fff;
  font-weight: 500;
}

.outP_title {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 40px;
}

.outP_title span {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

@media screen and (max-width: 700px) {
  .container {
    margin-top: 0;
    padding: 30px 20px 80px;
  }
  .title {
    font-size: 24px;
  }

  .pathBar li p {
    font-size: 14px;
  }

  /* step1 페이지 */
  .step1_subP {
    font-size: 13px;
  }

  .menu_box {
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }

  .menu_box label {
    width: calc(50% - 5px);
  }
}
