.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 440px;
  padding: 30px 20px;
}

.back {
  padding: 10px 0 10px;
  cursor: pointer;
}

.container h1 {
  font-size: 30px;
}

.container p {
  font-size: 16px;
  color: #666;
  max-width: 360px;
  margin: 20px 0;
}

.container span {
  font-weight: 600;
}

/* 아이디 찾기 */
.send_btn {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 1px solid #e2e2e2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 20px;
  margin: 40px 0 30px;
}

.send_btn h2 {
  margin: 0;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
  font-size: 18px;
}

.send_btn span {
  font-weight: 400;
  color: #797979;
}

/* 아이디찾기 > 비밀번호 찾기 버튼 영역 */
.find_password_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.find_password_box h2 {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #6cbed7;
  margin-bottom: 12px;
}

.find_password_btn {
  background-color: #eaf8fc;
  width: 180px;
  height: 46px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5caec7;
  border-radius: 100px;
  transition: all 0.3s ease-in-out;
}

.find_password_btn:hover {
  background-color: #ddf1f7;
  color: #5caec7;
}

/* 비밀번호 찾기  */

/* 인풋필드 */
.formBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.label {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 6px;
  margin-top: 14px;
  color: #666;
}

.input_field {
  font-size: 15px;
  padding: 14px 12px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.formBox button {
  width: 100%;
  height: 54px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 20px;
  background-color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.formBox button:hover {
  background-color: #5caec7;
}

/* 꼭 알두세요 */
.info_desk {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  row-gap: 6px;
}

.info_desk h2 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.info_desk li {
  display: flex;
  width: 100%;
  max-width: 100%;
  background-color: #f8fbff;
  border: 1px solid #e9f1fa;
  border-radius: 12px;
}

.info_desk li p {
  width: 100%;
  display: flex;
  padding: 14px;
  color: #666;
  font-size: 14px;
  margin: 0;
}

/* 에러메시지 */
.errorBox {
  width: 100%;
  background-color: #fff4f4;
  border-radius: 8px;
  padding: 4px 0;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorBox p {
  width: 100%;
  color: #f73f51;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 700px) {
  .container {
    padding-top: 50px;
    justify-content: flex-start;
  }

  .container h1 {
    font-size: 24px;
  }

  .container p {
    font-size: 14px;
  }
}
