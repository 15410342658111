.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  transition: all 0.3s ease-in-out;
}

.container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.titleBox h1 {
  font-size: 44px;
  line-height: 1.3;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.line {
  width: 210px;
  height: 54px;
  background-color: #aed9e9;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.p1 {
  color: #666;
  font-size: 18px;
  margin-bottom: 6px;
  transition: all 0.3s ease-in-out;
}

.p1 span {
  color: #f7ae1d;
  font-weight: 500;
}

.p2 {
  font-size: 18px;
  margin-top: 20px;
  color: #6cbed7;
}

/* 카드박스 */
.card_wrapper {
  overflow: hidden;
  width: 100%;
  max-width: 540px;
}

.card_container {
  white-space: nowrap;
  transition: ease 1000ms;
}

.card_Box_wrap {
  display: inline-block;
  width: 100%;
  border-radius: 40px;
}

.card_Box {
  width: 100%;
  display: flex;
  position: relative;
}

.carImg {
  width: 100%;
}

/* 이미지 카드 */
.b_card_wrapper {
  position: absolute;
  top: 0;
  left: 20px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  max-width: 360px;
  transition: all 0.3s ease-in-out;
}

.b_card_container {
  width: 100%;
  padding: 20px 24px;
  display: flex;
  transition: all 0.3s ease-in-out;
}

.cardImg {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  margin-top: 10px;
  width: 50px;
  height: 50px;
  transition: all 0.3s ease-in-out;
}

.card_doc {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  transition: all 0.3s ease-in-out;
}

.card_doc h1 {
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}

.card_doc p {
  font-size: 14px;
  color: #666;
  margin: 6px 0 10px;
  white-space: pre-line;
  transition: all 0.3s ease-in-out;
}

.card_doc span {
  font-size: 14px;
  color: #6cbed7;
  text-align: start;
  transition: all 0.3s ease-in-out;
}

/* dot 버튼 들 */
.slidesDots {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  column-gap: 8px;
  transition: all 0.3s ease-in-out;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 100px;
  cursor: pointer;

  background-color: #c3c3c3;
}

.active {
  background-color: #6cbed7;
  width: 20px;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    margin-top: 60px;
  }
  .container {
    flex-direction: column;
    align-items: center;
  }

  .titleBox h1 {
    font-size: 40px;
  }

  .line {
    height: 40px;
    width: 190px;
    top: 60px;
  }

  .titleBox {
    text-align: center;
  }

  /* 카드박스 */
  .card_Box_wrap {
    margin-top: 60px;
  }

  /* 슬라이드 닷 */
  .slidesDots {
    justify-content: center;
    margin-top: 16px;
  }
}

@media screen and (max-width: 580px) {
  .wrapper {
    margin-top: 40px;
  }
  .titleBox h1 {
    font-size: 26px;
  }

  .p1 {
    font-size: 16px;
  }

  .p2 {
    font-size: 13px;
  }

  .line {
    height: 28px;
    width: 130px;
    top: 40px;
    left: 13px;
  }

  /* 카드박스 */
  .card_wrapper {
    max-width: 400px;
  }

  /* 이미지 카드 */
  .b_card_wrapper {
    max-width: 280px;
    top: -20px;
  }

  .b_card_container {
    padding: 16px;
  }

  .card_doc h1 {
    font-size: 16px;
  }
  .cardImg {
    width: 40px;
    height: 40px;
  }

  .card_doc {
    margin-left: 16px;
  }
}
