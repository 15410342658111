.wrapper {
  background-image: linear-gradient(141deg, #e6eef7 18%, #e6f4f8 88%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  max-width: 300px;
}

.img {
  height: 230px;
}

.container h1 {
  margin-top: 10px;
  font-size: 28px;
}

.container p {
  font-size: 16px;
  text-align: center;
  color: #666666;
  margin: 10px 0 30px;
}

.btnBox {
  width: 100%;
  max-width: 240px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.btn1 {
  width: 100%;
  background-color: #6cbed7;
  height: 54px;
  border-radius: 12px;
  font-size: 16px;
  color: #fff;
}

.btn2 {
  width: 100%;
  background-color: #eaf8fc;
  border: 1px solid #cee2e8;
  height: 54px;
  border-radius: 12px;
  font-size: 16px;
  color: #6cbed7;
}

@media screen and (max-width: 500px) {
  .container h1 {
    font-size: 24px;
  }
  .img {
    height: 180px;
  }
}
