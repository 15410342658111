.wrapper {
  margin-top: 78px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.container {
  width: 100%;
  max-width: 600px;
  padding: 80px 20px 0;
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;
}

.ok_title {
  font-size: 24px;
  margin-bottom: 10px;
}

.title {
  font-size: 30px;
  transition: 0.3s ease-in-out;
}

.title span {
  font-size: 16px;
  color: #797979;
  font-weight: 500;
}

.subTitle {
  font-size: 18px;
  color: #666;
  margin-top: 16px;
  transition: 0.3s ease-in-out;
}

/* 버튼 */
.btn_wrap {
  width: 100%;
  display: flex;
  column-gap: 10px;
  margin-top: 30px;
}

.btn1 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #eaf8fc;
  color: #6cbed7;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn1:hover {
  background-color: #dff0f5;
}

.btn2 {
  width: 100%;
  height: 54px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 10px;
  background-color: #6cbed7;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn2:hover {
  background-color: #5caec7;
}

/* ========= */
/* 첫번째 데이터 */
.sec1_wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  border-radius: 12px;
}

.sec1_box {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 14px;
  column-gap: 40px;
}

.sec1_box li {
  width: calc(50% - 20px);
  display: flex;
  justify-content: space-between;
}

.sec1_box li p {
  font-size: 14px;
  color: #797979;
}

.sec1_box li h1 {
  font-size: 14px;
  font-weight: 500;
}

/* 두번째 데이터 */
.sec_title {
  margin: 60px 0 20px;
  font-size: 24px;
}

.sec2_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.sec2_wrap li {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.sec2_wrap li p {
  font-size: 14px;
  color: #666;
}

.sec2_wrap li h1 {
  font-size: 18px;
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 30px 20px 0;
  }

  .ok_title {
    font-size: 20px;
  }

  .title {
    font-size: 24px;
  }

  .subTitle {
    font-size: 16px;
  }

  .sec1_box {
    flex-direction: column;
  }

  .sec1_box li {
    width: 100%;
  }
}
